@keyframes fadeInOut {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}

.fade-in-out {
  animation: fadeInOut 1s infinite;
}