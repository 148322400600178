//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

@import '../../globals/scss/typography';

/// Code snippet base styles
/// @access private
/// @group code-snippet
@mixin bx--snippet {
  @include type-style('code-01');

  position: relative;
  width: 100%;
  max-width: to-rem(768px);
  background-color: $field-01;
}
