// carbon DS classes modification
.cds--btn {
  border-radius: 8px;
}

.cds--btn--primary {
  background-color: #00acff;

  &:hover {
    background-color: #0099e2;
  }
  &:focus {
    border-color: #0099e2;
    box-shadow: inset 0 0 0 1px #0099e2, inset 0 0 0 2px #ffffff;
  }
  &:active {
    background-color: #005d89;
  }
}

.cds--btn--tertiary {
  color: unset;
  border-color: #d1d1db;

  &:hover,
  &:focus,
  &:active {
    border-color: transparent;
    background-color: #f7f7f8;
    color: unset;
  }

  &:hover {
    border-color: #d1d1db;
  }

  &:focus,
  &:active {
    border-color: #d1d1db;
    box-shadow: inset 0 0 0 1px #d1d1db, inset 0 0 0 2px #ffffff;
  }
}

.cds--text-input,
.cds--list-box__field,
.cds--text-area,
.cds--date-picker.cds--date-picker--single .cds--date-picker__input,
.cds--number input[type="number"] {
  border: none;
  background: #fff;
  border: 1px solid #d1d1db;
  border-radius: 8px;

  &:focus,
  &:active {
    outline-color: #00acff;
  }

  &.md {
    height: 40px;
  }
}

.cds--number {
  &__controls {
    display: none;
  }
}
.cds--number input[type="number"],
.cds--number--xl.cds--number input[type="number"],
.cds--number--lg.cds--number input[type="number"] {
  padding-inline: 1rem;
  font-family: "Falak" !important;
  font-size: 14px;
  letter-spacing: normal;
}

.cds--form-item {
  &.invalid {
    .cds--text-input,
    .cds--list-box__field,
    .cds--text-area,
    .cds--date-picker.cds--date-picker--single .cds--date-picker__input {
      border-color: #f53d6b;

      &:focus,
      &:active {
        outline-color: #f53d6b;
      }
    }

    .cds--form-requirement {
      font-weight: 400;
      font-size: 14px;
      color: #f53d6b;
    }

    .cds--text-area {
      padding-inline-end: 2rem;
    }
  }

  &.disabled {
    label {
      pointer-events: none;
    }

    .cds--text-input,
    .cds--list-box__field,
    .cds--text-area,
    .cds--date-picker.cds--date-picker--single .cds--date-picker__input {
      background-color: #f7f7f8;
      pointer-events: none;
    }
  }
}

.cds--date-picker.cds--date-picker--single .cds--date-picker {
  &__input {
    font-family: "Falak";
    line-height: 24px;
    letter-spacing: unset;
  }

  &_icon {
    color: red;
  }
}

.cds--date-picker--single,
.cds--date-picker-container,
.cds--date-picker.cds--date-picker--single .cds--date-picker__input {
  width: 100%;
}

.cds--list-box {
  border: none;
  background-color: unset !important;

  &__label {
    line-height: 24px;
  }

  &__menu {
    background-color: white;
    border-radius: 8px;
    margin-top: 8px;

    &-item {
      &__option {
        padding-right: 0;
      }
      &__selected-icon {
        display: none !important;
      }
      &:first-of-type {
        .cds--list-box__menu-item__option {
          border-start-end-radius: 8px;
          border-start-start-radius: 8px;
        }
      }
      &:last-of-type {
        .cds--list-box__menu-item__option {
          border-end-start-radius: 8px;
          border-end-end-radius: 8px;
        }
      }
    }
  }
}

.cds--dropdown {
  border-radius: 8px;
}

.cds--dropdown__wrapper {
  .cds--dropdown--invalid {
    outline-color: #f53d6b;
    outline-width: 1px;
    outline-offset: -1px;

    .cds--list-box__field {
      outline-color: #f53d6b;
      border-color: transparent;
    }

    .cds--form-requirement {
      color: #f53d6b;
    }
  }
}

.cds--list-box__field {
  height: 100%;
}

.cds--dropdown__invalid-icon {
  display: none;
}

.cds--date-picker__input:has(.cds--date-picker__icon--invalid) {
}
.cds--date-picker-input__wrapper--invalid {
  .cds--date-picker__input {
    outline-color: #f53d6b;
    border-color: #f53d6b;
  }
}

.cds--label,
.cds--label p {
  font-size: 14px;
  font-weight: 500;
  color: #161616;
  line-height: 21px;
}
cds-text-label:has(input[type="tel"]) {
  position: relative;

  .cds--text-input__field-wrapper {
    position: absolute;

    .cds--text-input__invalid-icon {
      top: calc(
        29px + 24px
      ); // 24px is the half of 48px the large input, set it to 20px if the input is medium
    }
  }
}
.cds--label:has(input[type="tel"]) {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-bottom: 0;
}

.cds--list-box__menu-icon {
  justify-content: flex-end;
  margin-inline-end: -3px;
}

.cds--list-box--disabled .cds--list-box__field {
  border-bottom-color: unset;
}

.cds-loading {
  &-lg {
    display: flex;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    position: fixed;
    align-items: center;
    justify-content: center;
    z-index: 1000;

    .cds--loading {
      width: 4rem;
      height: 4rem;
    }
  }

  &-disabled {
    .cds--loading__stroke {
      stroke: #8d8d8d;
    }
  }
}

.fi-arrow-left,
.fi-chevron-left {
  &.reverse {
    transform: scaleX(-1);
  }
}

.cds--text-area:invalid {
  padding-inline-end: 2rem;
}

.cds--text-input__invalid-icon,
.cds--text-input__readonly-icon,
.cds--number__invalid,
.cds--number--xl .cds--number__invalid,
.cds--number--lg .cds--number__invalid,
.cds--text-area__invalid-icon {
  right: unset;
  inset-inline-end: 1rem;
}

.cds--radio-button-group {
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.cds--radio-button-wrapper {
  margin: 0 !important; 
}
.cds--radio-button__label {
  gap: 12px;
  padding-block: 3px;
}
.cds--radio-button__appearance {
  margin: 0;
  border-color: #d1d1db;
  outline: none !important;
}
.cds--radio-button:checked + .cds--radio-button__label .cds--radio-button__appearance {
  border-color: #00ACFF;
  &::before {
    background-color: #00ACFF;
  }
}


/* ============================================= */
/* ==================== RTL ==================== */
/* ============================================= */
.rtl {
  .fi-arrow-left,
  .fi-chevron-left {
    transform: scaleX(-1) !important;

    &.reverse {
      transform: scaleX(1) !important;
    }
  }
  .cds--list-box__menu-icon {
    right: unset;
    left: 1rem;
  }
  .cds--list-box__field {
    padding: 0 1rem 0 3rem;
  }
  .cds--date-picker__input {
    direction: ltr;
  }
  .cds--text-input, .cds--text-input input {
    direction: ltr !important;
    text-align: end;
  }
}
