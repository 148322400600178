//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

//-----------------------------
// List Box
//-----------------------------

@import '../../globals/scss/vendor/@carbon/elements/scss/import-once/import-once';
@import '../../globals/scss/helper-mixins';
@import '../../globals/scss/vars';
@import '../checkbox/checkbox';
@import '../list-box/list-box';

/// Multi select styles
/// @access private
/// @group multi-select
@mixin multiselect {
  .#{$prefix}--multi-select .#{$prefix}--list-box__field--wrapper {
    display: inline-flex;
    width: 100%;
    height: calc(100% + 1px);
    align-items: center;
  }

  .#{$prefix}--multi-select .#{$prefix}--list-box__field:focus {
    @include focus-outline('reset');
  }

  .#{$prefix}--multi-select .#{$prefix}--tag {
    min-width: auto;
    margin: 0 $spacing-03 0 $spacing-05;
  }

  .#{$prefix}--multi-select--filterable .#{$prefix}--tag {
    margin: 0 $carbon--spacing-03 0 $carbon--spacing-05;
  }

  .#{$prefix}--multi-select .#{$prefix}--list-box__menu {
    min-width: auto;
  }

  .#{$prefix}--multi-select
    .#{$prefix}--list-box__menu-item__option
    .#{$prefix}--checkbox-wrapper {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
  }

  .#{$prefix}--multi-select
    .#{$prefix}--list-box__menu-item__option
    .#{$prefix}--checkbox-label {
    display: inline-block;
    overflow: hidden;
    width: 100%;
    padding-left: to-rem(28px);
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .#{$prefix}--multi-select
    .#{$prefix}--list-box__menu-item__option
    > .#{$prefix}--form-item {
    flex-direction: row;
    margin: 0;
  }

  .#{$prefix}--multi-select
    .#{$prefix}--list-box__menu-item
    .#{$prefix}--checkbox:checked
    ~ .#{$prefix}--checkbox-label-text {
    color: $text-01;
  }

  .#{$prefix}--multi-select--filterable {
    transition: outline-color $duration--fast-01 motion(standard, productive);
  }

  .#{$prefix}--multi-select--filterable.#{$prefix}--combo-box
    .#{$prefix}--text-input {
    border: to-rem(2px) solid transparent;
    background-clip: padding-box;
    outline: none;
  }

  .#{$prefix}--multi-select--filterable--input-focused,
  .#{$prefix}--multi-select
    .#{$prefix}--list-box__field--wrapper--input-focused {
    @include focus-outline('outline');
  }

  .#{$prefix}--multi-select--filterable.#{$prefix}--multi-select--selected
    .#{$prefix}--text-input,
  .#{$prefix}--multi-select.#{$prefix}--multi-select--selected
    .#{$prefix}--list-box__field {
    padding-left: 0;
  }

  .#{$prefix}--multi-select--filterable.#{$prefix}--list-box--disabled:hover
    .#{$prefix}--text-input {
    background-color: $field-01;
  }

  .#{$prefix}--multi-select--filterable
    .#{$prefix}--list-box__selection--multi {
    margin: 0 0 0 $spacing-05;
  }

  .#{$prefix}--multi-select--filterable.#{$prefix}--multi-select--inline,
  .#{$prefix}--multi-select--filterable.#{$prefix}--multi-select--inline
    .#{$prefix}--text-input {
    border-bottom: 0;
    background-color: transparent;
  }

  .#{$prefix}--multi-select:not(.#{$prefix}--list-box--expanded)
    .#{$prefix}--list-box__menu {
    visibility: hidden;
  }
}

@include exports('multi-select') {
  @include multiselect;
}
