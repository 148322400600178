//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @access private
/// @group tag
@mixin tag-theme($bg-color, $text-color, $filter-hover-color: $bg-color) {
  background-color: $bg-color;
  color: $text-color;

  &.#{$prefix}--tag--interactive,
  .#{$prefix}--tag__close-icon {
    &:hover {
      background-color: $filter-hover-color;
    }
  }
}
