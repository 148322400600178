// Code generated by @carbon/layout. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access public
/// @group @carbon/layout
$size-xs: 1.5rem;
$size-sm: 2rem;
$size-md: 2.5rem;
$size-lg: 3rem;
$size-xl: 4rem;
$size-2xl: 5rem;
