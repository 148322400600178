@font-face {
  font-family: 'FalakIcons';
  src:  url('../icons/FalakIcons.eot?d85ded');
  src:  url('../icons/FalakIcons.eot?d85ded#iefix') format('embedded-opentype'),
    url('../icons/FalakIcons.ttf?d85ded') format('truetype'),
    url('../icons/FalakIcons.woff?d85ded') format('woff'),
    url('../icons/FalakIcons.svg?d85ded#FalakIcons') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="fi-"], [class*=" fi-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'FalakIcons' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.fi-logout:before {
  content: "\e914";
}
.fi-globe:before {
  content: "\e915";
}
.fi-file-stack:before {
  content: "\e916";
}
.fi-money:before {
  content: "\e90b";
}
.fi-arrow-left:before {
  content: "\e900";
}
.fi-camera:before {
  content: "\e901";
}
.fi-check:before {
  content: "\e902";
}
.fi-check-circle:before {
  content: "\e903";
}
.fi-chevron-left:before {
  content: "\e904";
}
.fi-company:before {
  content: "\e905";
}
.fi-download:before {
  content: "\e906";
}
.fi-home:before {
  content: "\e907";
}
.fi-menu:before {
  content: "\e908";
}
.fi-message:before {
  content: "\e909";
}
.fi-message-1:before {
  content: "\e90a";
}
.fi-plus:before {
  content: "\e90c";
}
.fi-plus-circle:before {
  content: "\e90d";
}
.fi-profile:before {
  content: "\e90e";
}
.fi-request:before {
  content: "\e90f";
}
.fi-smartphone:before {
  content: "\e910";
}
.fi-tag:before {
  content: "\e911";
}
.fi-trash:before {
  content: "\e912";
}
.fi-warning-triangle:before {
  content: "\e913";
}
