.mt-4 {
  margin-top: 4px;
}
.mt-8 {
  margin-top: 8px;
}
.mt-12 {
  margin-top: 12px;
}
.mt-16 {
  margin-top: 16px;
}
.mt-24 {
  margin-top: 24px;
}
.mt-32 {
  margin-top: 32px;
}

.ms-4 {
  margin-inline-start: 4px;
}
.ms-8 {
  margin-inline-start: 8px;
}
.ms-12 {
  margin-inline-start: 12px;
}
.ms-16 {
  margin-inline-start: 16px;
}
.ms-24 {
  margin-inline-start: 24px;
}

.mb-auto {
  margin-bottom: auto;
}
.mi-auto {
  margin-inline: auto;
}
.mb-12 {
  margin-bottom: 12px;
}
.mb-32 {
  margin-bottom: 32px;
}

.p-16 {
  padding: 16px;
}

.pt-0 {
  padding-top: 0 !important;
}
.pt-16 {
  padding-top: 16px !important;
}
.pt-24 {
  padding-top: 24px;
}

.gap-2 {
  gap: 2px;
}
.gap-4 {
  gap: 4px;
}
.gap-8 {
  gap: 8px;
}
.gap-12 {
  gap: 12px;
}
.gap-16 {
  gap: 16px;
}
.gap-24 {
  gap: 24px;
}

.t-align-c {
  text-align: center;
}
.t-align-end {
  text-align: end;
}
.flx {
  display: flex;
}
.flx-align-c {
  display: flex;
  align-items: center;
}
.flx-c {
  display: flex;
  align-items: center;
  justify-content: center;
}
.flx-sb {
  justify-content: space-between;
}
.flx-col {
  display: flex;
  flex-direction: column;
}
.flx-1 {
  flex: 1;
}

.max-w-300 {
  max-width: 300px;
}
.form-group {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  flex-direction: column;
  gap: 16px;
}

.badge {
  border-radius: 24px;
  height: 24px;
  line-height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgb(16 16 16 / 8%);
  background-color: #00acff;
  color: #fff;

  &-neutral {
    background: #f7f7f8;
    color: unset;
    border: 1px solid #d1d1db;
    width: unset;
    padding-inline: 12px;
    height: unset;
    min-height: 24px;
  }
}

.link {
  font-size: 14px;
  font-weight: 500px;
  color: #00acff;
  text-decoration: underline;
  cursor: pointer;
  
  i {
    text-decoration: none;
  }

  &-btn {
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  }
}
.link-icon {
  font-size: 24px;
  color: #00acff;
  cursor: pointer;
}

.icon-link {
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 8px;
  color: unset;

  span {
    text-decoration: underline;
  }

  i {
    font-size: 28px;
  }
}

.chip {
  padding: 4px 12px;
  border: 1px solid #d1d1db;
  background-color: #f7f7f8;
  color: #3f3f50;
  border-radius: 8px;
  max-width: max-content;

  &-success {
    color: #17663a;
    background-color: #eefbf4;
    border-color: #b2eecc;
  }

  &-info {
    color: #0075ad;
    background-color: #f0faff;
    border-color: #ade4ff;
  }

  &-danger {
    color: #d50b3e;
    background-color: #fef0f4;
    border-color: #fbb1c4;
  }
}

.big-add-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  padding: 12px 16px;
  color: unset;
  line-height: 24px;
  border-radius: 8px;
  border: 2px dashed #fff;
  background: linear-gradient(#fff 0 0) padding-box,
    linear-gradient(135deg, #00acff, #f75fde) border-box;

  cursor: pointer;

  i {
    font-size: 24px;
    background: linear-gradient(45deg, #00acff, #f75fde);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.btn-icon {
  gap: 4px;
  font-weight: 500;

  i {
    font-size: 20px;
    font-weight: 400;
  }
}

.inline-card {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  border-radius: 8px;
  gap: 4px;
  background-color: #f7f7f8;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &-dark {
    background-color: #ebebef;
  }

  &-success {
    * {
      color: #17663a !important;
    }
    background-color: #ebfbf4;
  }
}

.out-of-boundary {
  margin-inline: -20px;
  padding-inline: 20px;
  width: calc(100% + 40px) !important;
}

.hide-scrollbars {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;

  &::-webkit-scrollbar {
    display: none;
  }
}

.pointer {
  cursor: pointer;
}

.avatar,
.fake-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.fake-avatar {
  background-color: #d1d1db;
}

.page-head-with-badge {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 24px;
  border-bottom: 1px solid #ebebef;

  .page-title {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.truncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}

.txt-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-footer {
  position: fixed;
  bottom: 0;
  inset-inline: 0;
  padding: 8px 20px;
  background-color: #f6f6f6;
  line-height: 24px;
  max-width: 800px;
  margin-inline: auto;
  z-index: 997;
  border-top: 1px solid #d1d1db;
}

.lang-switch {
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
  padding: 0;
}
