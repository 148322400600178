//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access private
/// @group loading
$radius: 44;

/// @type Number
/// @access private
/// @group loading
$circumference: $radius * 2 * 3.1416;

/// @type Number
/// @access private
/// @group loading
$loading__size: 5.5rem;
