// define the classes prefix variable -MANDATORY- for carbon DS
$prefix: "cds";
// import global styles of carbon DS
@import "../node_modules/carbon-components/scss/globals/scss/styles.scss";
// import custom theme ( modifies the default styles of carbon DS )
@import "assets/scss/theme.scss";
// import custom font/typography
@import "assets/scss/typography.scss";
// import custom classes ( frequently used styles )
@import "assets/scss/custom-classes.scss";
// import custom animations
@import "assets/scss/animations.scss";
// import icomoon file ( configuration for icons font )
@import "assets/scss/icomoon.scss";
// import styles for ngNeat hotToast lib
@import "../node_modules/@ngneat/hot-toast/src/styles/styles.scss";

// globals
.rtl,
.rtl * {
  direction: rtl;
}

body {
  font-family: "Falak" !important;
  padding-block: 74px 64px; // to prevent overlap with the fixed header & bottomNav
  // max-width: 800px;
  // margin-inline: auto;
}

app-root {
  max-width: 800px;
  display: block;
  margin-inline: auto;
}

input {
  font-family: 'Falak' !important;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    -webkit-appearance: none;
}

a {
  text-decoration: none;
}

router-outlet {
  display: none;
}

.btn {
  &-lg {
    min-height: 3rem;
  }

  &-full-width {
    max-width: unset;
    justify-content: center;
    padding-inline: 1rem;
  }

  &-rounded {
    border-radius: 999px;
    background-color: #121217;
    padding: 8px 16px;

    &:hover {
      background-color: #3F3F50 !important;
    }
    &:focus {
      border-color: #272727;
      box-shadow: inset 0 0 0 1px #272727, inset 0 0 0 2px #ffffff;
    }
    &:disabled {
      background-color: #D1D1Db;
    }
  }
}

.inpt {
  &-one-digit {
    width: 56px;
    height: 64px;
    text-align: center;
  }
}

.page-container {
  display: flex;
  flex-direction: column;
  padding: 40px 20px 20px;
  gap: 16px;
  width: 100%;
  // max-width: 600px;
  margin-inline: auto;
  
  &-justify-center {
    min-height: calc(100vh - 138px);
    justify-content: center;
  }
}