@mixin content-visible {
  height: 100%;
  opacity: 1;
  visibility: inherit;
}

@mixin content-hidden {
  height: 0;
  opacity: 0;
  visibility: hidden;
}

@keyframes collapse-accordion {
  0% {
    @include content-visible;
  }

  100% {
    @include content-hidden;
  }
}

@keyframes expand-accordion {
  0% {
    @include content-hidden;
  }

  100% {
    @include content-visible;
  }
}
