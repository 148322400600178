// Code generated by @carbon/layout. DO NOT EDIT.
//
// Copyright IBM Corp. 2018, 2019
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-01: 0.125rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-02: 0.25rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-03: 0.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-04: 0.75rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-05: 1rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-06: 1.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-07: 2rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-08: 2.5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-09: 3rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-10: 4rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-11: 5rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-12: 6rem !default;

/// @type Number
/// @access public
/// @group @carbon/layout
$carbon--spacing-13: 10rem !default;

/// @type List
/// @access public
/// @group @carbon/layout
$carbon--spacing: (
  $carbon--spacing-01,
  $carbon--spacing-02,
  $carbon--spacing-03,
  $carbon--spacing-04,
  $carbon--spacing-05,
  $carbon--spacing-06,
  $carbon--spacing-07,
  $carbon--spacing-08,
  $carbon--spacing-09,
  $carbon--spacing-10,
  $carbon--spacing-11,
  $carbon--spacing-12,
  $carbon--spacing-13
);

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-01
$spacing-01: $carbon--spacing-01 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-02
$spacing-02: $carbon--spacing-02 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-03
$spacing-03: $carbon--spacing-03 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-04
$spacing-04: $carbon--spacing-04 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-05
$spacing-05: $carbon--spacing-05 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-06
$spacing-06: $carbon--spacing-06 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-07
$spacing-07: $carbon--spacing-07 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-08
$spacing-08: $carbon--spacing-08 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-09
$spacing-09: $carbon--spacing-09 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-10
$spacing-10: $carbon--spacing-10 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-11
$spacing-11: $carbon--spacing-11 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-12
$spacing-12: $carbon--spacing-12 !default;

/// @type Number
/// @access public
/// @group @carbon/layout
/// @alias carbon--spacing-13
$spacing-13: $carbon--spacing-13 !default;
