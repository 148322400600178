// @font-face {
//   font-family: "Falak";
//   src: url("./../fonts/TeshrinAR+LT-Regular.otf");
// }
@font-face {
  font-family: "Falak";
  src: url("./../fonts/TeshrinAR+LT-Regular.otf");
  font-weight: 400;
}
@font-face {
  font-family: "Falak";
  src: url("./../fonts/TeshrinAR+LT-Medium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Falak";
  src: url("./../fonts/TeshrinAR+LT-Bold.otf");
  font-weight: 600;
}
@font-face {
  font-family: "Falak";
  src: url("./../fonts/TeshrinAR+LT-Heavy.otf");
  font-weight: 700;
}

// custom typography classes
.txt {
  line-height: 24px;
  font-size: 16px;
}
.txt-sm {
  font-size: 18px;
  font-weight: 600;
}
.txt-md {
  font-size: 20px;
  font-weight: 600;
}
.txt-lg {
  font-size: 30px;
  font-weight: 600;
}
.txt-c {
  text-align: center;
}

.fs-24 {
  font-size: 24px;
}

.txt-caption {
  font-size: 14px;
  font-weight: 500;
  color: #6c6c89;

  &-black {
    color: #161616; // default
  }

  &-danger {
    color: #f53d6b;
  }
}

.txt-xs {
  font-size: 12px;
}

.fw-400 {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.fw-600 {
  font-weight: 600;
}
.fw-800 {
  font-weight: 800;
}

.txt-gradient {
  background: linear-gradient(135deg, #00afff 0%, #ff50e4 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
