//
// Copyright IBM Corp. 2016, 2018
//
// This source code is licensed under the Apache-2.0 license found in the
// LICENSE file in the root directory of this source tree.
//

//-----------------------------
// Search
//-----------------------------

@import '../../globals/scss/vars';
@import '../../globals/scss/helper-mixins';
@import '../../globals/scss/css--reset';
@import '../../globals/scss/typography';
@import '../../globals/scss/import-once';

/// Search styles
/// @access private
/// @group search
@mixin search {
  .#{$prefix}--search {
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
  }

  .#{$prefix}--search .#{$prefix}--label {
    @include hidden;
  }

  .#{$prefix}--search-input {
    @include reset;
    @include type-style('body-short-01');
    @include focus-outline('reset');

    width: 100%;
    order: 1;
    padding: 0 $carbon--spacing-08;
    border: none;
    border-bottom: 1px solid $ui-04;
    appearance: none;
    background-color: $field-01;
    color: $text-01;
    text-overflow: ellipsis;
    transition: background-color $duration--fast-02 motion(standard, productive),
      outline $duration--fast-02 motion(standard, productive);

    &:focus {
      @include focus-outline('outline');
    }

    &::placeholder {
      @include placeholder-colors;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .#{$prefix}--search-input[disabled] {
    border-bottom: 1px solid transparent;
    background-color: $field;
    color: $disabled;
    cursor: not-allowed;

    &::placeholder {
      color: $disabled;
    }
  }

  .#{$prefix}--search--light .#{$prefix}--search-input {
    background: $field-02;
  }

  .#{$prefix}--search--light .#{$prefix}--search-close::before {
    background: $field-02;
  }

  // Small styles
  .#{$prefix}--search--sm .#{$prefix}--search-input,
  .#{$prefix}--search--sm.#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-input {
    height: to-rem(32px);
    // 8px padding on either side of icon + 16px icon (32px)
    padding: 0 $spacing-07;
  }

  .#{$prefix}--search--sm .#{$prefix}--search-magnifier-icon {
    left: to-rem(8px);
  }

  // Large styles
  // V11: change lg to md
  .#{$prefix}--search--lg .#{$prefix}--search-input,
  .#{$prefix}--search--lg.#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-input {
    height: to-rem(40px);
    // 12px padding on either side of icon + 16px icon (40px)
    padding: 0 $spacing-08;
  }

  // V11: change lg to md
  .#{$prefix}--search--lg .#{$prefix}--search-magnifier-icon {
    left: to-rem(12px);
  }

  // V11: change xl to lg
  .#{$prefix}--search--xl .#{$prefix}--search-input,
  .#{$prefix}--search--xl.#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-input {
    height: to-rem(48px);
    // 16px padding on either side of icon + 16px icon (48px)
    padding: 0 $spacing-09;
  }

  .#{$prefix}--search-magnifier-icon {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: $spacing-05;
    width: to-rem(16px);
    height: to-rem(16px);
    fill: $icon-02;
    pointer-events: none;
    transform: translateY(-50%);
  }

  .#{$prefix}--search-close {
    @include button-reset(false);
    @include focus-outline('reset');

    position: absolute;
    top: 0;
    right: 0;

    &::before {
      position: absolute;
      top: to-rem(1px);
      left: 0;
      display: block;
      width: 2px;
      height: calc(100% - 2px);
      background-color: $field-01;
      content: '';
      transition: background-color $duration--fast-02
        motion(standard, productive);

      @media screen and (prefers-reduced-motion: reduce) {
        transition: none;
      }
    }

    &:hover {
      border-bottom: 1px solid $ui-04;

      &::before {
        background-color: $hover-field;
      }
    }
  }

  .#{$prefix}--search-button {
    flex-shrink: 0;
    margin-left: $carbon--spacing-01;
    background-color: $field-01;

    svg {
      fill: currentColor;
      vertical-align: middle;
    }
  }

  .#{$prefix}--search-close svg {
    fill: inherit;
  }

  .#{$prefix}--search-close,
  .#{$prefix}--search-button {
    display: flex;
    width: to-rem(40px);
    height: to-rem(40px);
    align-items: center;
    justify-content: center;
    border-width: 1px 0;
    border-style: solid;
    border-color: transparent;
    cursor: pointer;
    fill: $icon-01;
    opacity: 1;
    transition: opacity $duration--fast-02 motion(standard, productive),
      background-color $duration--fast-02 motion(standard, productive),
      outline $duration--fast-02 motion(standard, productive),
      border $duration--fast-02 motion(standard, productive);
    visibility: inherit;

    &:hover {
      background-color: $hover-field;
    }

    &:focus {
      @include focus-outline('outline');
    }

    &:active {
      @include focus-outline('outline');

      background-color: $selected-ui;
    }
  }

  .#{$prefix}--search--disabled .#{$prefix}--search-close,
  .#{$prefix}--search--disabled.#{$prefix}--search--expandable
    .#{$prefix}--search-magnifier {
    cursor: not-allowed;
    outline: none;

    &:hover {
      border-bottom-color: transparent;
      background-color: transparent;
    }

    &:hover::before {
      background-color: transparent;
    }
  }

  .#{$prefix}--search--disabled svg {
    fill: $disabled;
  }

  .#{$prefix}--search-close:focus,
  .#{$prefix}--search-close:active {
    &::before {
      background-color: $focus;
    }
  }

  .#{$prefix}--search-input:focus ~ .#{$prefix}--search-close:hover {
    @include focus-outline('outline');
  }

  .#{$prefix}--search--sm {
    .#{$prefix}--search-close,
    ~ .#{$prefix}--search-button,
    &.#{$prefix}--search--expandable,
    &.#{$prefix}--search--expandable .#{$prefix}--search-magnifier {
      width: to-rem(32px);
      height: to-rem(32px);
    }

    &.#{$prefix}--search--expandable .#{$prefix}--search-input::placeholder {
      padding: 0 to-rem(32px);
    }
  }

  // V11: change lg to md
  .#{$prefix}--search--lg {
    .#{$prefix}--search-close,
    ~ .#{$prefix}--search-button,
    &.#{$prefix}--search--expandable,
    &.#{$prefix}--search--expandable .#{$prefix}--search-magnifier {
      width: to-rem(40px);
      height: to-rem(40px);
    }

    &.#{$prefix}--search--expandable .#{$prefix}--search-input::placeholder {
      padding: 0 to-rem(40px);
    }
  }

  // V11: change xl to lg
  .#{$prefix}--search--xl {
    .#{$prefix}--search-close,
    ~ .#{$prefix}--search-button,
    &.#{$prefix}--search--expandable,
    &.#{$prefix}--search--expandable .#{$prefix}--search-magnifier {
      width: to-rem(48px);
      height: to-rem(48px);
    }

    &.#{$prefix}--search--expandable .#{$prefix}--search-input::placeholder {
      padding: 0 to-rem(48px);
    }
  }

  .#{$prefix}--search-close--hidden {
    opacity: 0;
    visibility: hidden;
  }

  // V11: change xl to lg, lg to md
  .#{$prefix}--search--xl.#{$prefix}--skeleton .#{$prefix}--search-input,
  .#{$prefix}--search--lg.#{$prefix}--skeleton .#{$prefix}--search-input,
  .#{$prefix}--search--sm.#{$prefix}--skeleton .#{$prefix}--search-input {
    @include skeleton;

    width: 100%;

    &::placeholder {
      color: transparent;
    }
  }

  .#{$prefix}--search--expandable {
    transition: width $duration--fast-01 motion(standard, productive);
  }

  .#{$prefix}--search--expandable.#{$prefix}--search--expanded {
    width: 100%;
  }

  .#{$prefix}--search--expandable .#{$prefix}--search-input {
    width: 0;
    padding: 0;
    transition: padding $duration--fast-01 motion(standard, productive),
      width 0s linear $duration--fast-01;

    &::placeholder {
      position: relative;
      opacity: 0;
      transition-duration: $duration--fast-01;
      transition-property: padding, opacity;
      transition-timing-function: motion(standard, productive);
    }
  }

  .#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-input {
    width: 100%;
    transition: padding $duration--fast-01 motion(standard, productive);

    &::placeholder {
      position: relative;
      padding: 0;
      opacity: 1;
    }
  }

  .#{$prefix}--search--expandable .#{$prefix}--search-magnifier {
    position: absolute;
    cursor: pointer;
  }

  .#{$prefix}--search--expandable .#{$prefix}--search-magnifier:hover {
    background-color: $hover-ui;
  }

  .#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-magnifier {
    pointer-events: none;
  }

  .#{$prefix}--search--expandable .#{$prefix}--search-magnifier-icon {
    fill: $icon-01;
  }

  .#{$prefix}--search--expandable.#{$prefix}--search--expanded
    .#{$prefix}--search-magnifier-icon {
    fill: $icon-02;
  }

  // Windows HCM fix
  .#{$prefix}--search-close svg,
  .#{$prefix}--search-magnifier-icon {
    @include high-contrast-mode('icon-fill');
  }
}

@include exports('search') {
  @include search;
}
